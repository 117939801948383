var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-md-5 pt-5 container",staticStyle:{"display":"flex","padding-bottom":"120px","position":"relative"}},[_c('vue-progress-bar'),(_vm.page)?_c('div',{staticClass:"course px-lg-5 w-100"},[_c('div'),_c('h1',{staticClass:"course__title"},[_vm._v(" "+_vm._s(_vm.page.name)+" ")]),_c('div',{staticClass:"course__content"},[(_vm.page.user_progress)?_c('transition-group',{attrs:{"name":"fade"}},[_vm._l((_vm.page.user_progress.places),function(item,index){return [_c('div',{key:index,staticClass:"course__item",attrs:{"id":("story-" + index)}},[(!item.is_author_message && item.type === 'message')?_c('CharMessage',{attrs:{"place_id":item.id,"direction":item.is_hero ? 'right' : 'left',"button":index == _vm.page.user_progress.places.length - 1 &&
                !_vm.page.user_progress.completed,"object":item,"page":_vm.pageId,"sequence_no":item.sequence_no}}):(item.is_author_message && item.type === 'message')?_c('Message',{attrs:{"place_id":item.id,"button":index == _vm.page.user_progress.places.length - 1 &&
                !_vm.page.user_progress.completed,"object":item,"page":_vm.pageId,"sequence_no":item.sequence_no}}):(item.type === 'safetext')?_c('SafeText',{attrs:{"place_id":item.id,"button":index == _vm.page.user_progress.places.length - 1 &&
                !_vm.page.user_progress.completed,"object":item,"page":_vm.pageId,"sequence_no":item.sequence_no}}):(item.type === 'theory')?_c('Theory',{attrs:{"place_id":item.id,"button":index == _vm.page.user_progress.places.length - 1 &&
                !_vm.page.user_progress.completed,"object":item,"page":_vm.pageId,"sequence_no":item.sequence_no}}):(item.type === 'openquestion')?_c('OpenQuestion',{attrs:{"place_id":item.id,"complete":index != _vm.page.user_progress.places.length - 1,"button":index == _vm.page.user_progress.places.length - 1 &&
                !_vm.page.user_progress.completed,"page":_vm.pageId,"sequence_no":item.sequence_no,"object":item}}):(item.type === 'openquestionexpert')?_c('OpenQuestionExpert',{attrs:{"place_id":item.id,"complete":index != _vm.page.user_progress.places.length - 1,"button":index == _vm.page.user_progress.places.length - 1 &&
                !_vm.page.user_progress.completed,"page":_vm.pageId,"sequence_no":item.sequence_no,"object":item}}):(item.type === 'question')?_c('Question',{attrs:{"place_id":item.id,"button":index == _vm.page.user_progress.places.length - 1 &&
                !_vm.page.user_progress.completed,"page":_vm.pageId,"sequence_no":item.sequence_no,"object":item}}):(item.type === 'questionanswercheck')?_c('QuestionCheck',{attrs:{"place_id":item.id,"button":index == _vm.page.user_progress.places.length - 1 &&
                !_vm.page.user_progress.completed,"page":_vm.pageId,"sequence_no":item.sequence_no,"object":item}}):(item.type === 'questionrange')?_c('QuestionRange',{attrs:{"place_id":item.id,"button":index == _vm.page.user_progress.places.length - 1 &&
                !_vm.page.user_progress.completed,"page":_vm.pageId,"sequence_no":item.sequence_no,"object":item}}):(item.type === 'questionuserchoice')?_c('Choose',{attrs:{"place_id":item.id,"button":index == _vm.page.user_progress.places.length - 1 &&
                !_vm.page.user_progress.completed,"page":_vm.pageId,"sequence_no":item.sequence_no,"object":item}}):(item.type === 'questionexternal')?_c('Ext',{attrs:{"place_id":item.id,"button":index == _vm.page.user_progress.places.length - 1 &&
                !_vm.page.user_progress.completed,"page":_vm.pageId,"sequence_no":item.sequence_no,"object":item}}):_vm._e()],1)]})],2):_vm._e(),_c('div',{staticClass:"course__end",attrs:{"id":"end"}},[(
            _vm.page.user_progress.completed &&
            !_vm.page.user_progress.fun &&
            this.page.user_progress.next_page
          )?_c('EndCourse',{attrs:{"page":_vm.page.id,"show_mark":_vm.simInfo.show_page_mark},on:{"endPage":_vm.nextPage}}):(
            _vm.page.user_progress.completed &&
            _vm.page.user_progress.fun &&
            this.page.user_progress.next_page
          )?_c('EndCourse',{attrs:{"page":_vm.page.id,"fun":_vm.page.user_progress.fun,"utility":_vm.page.user_progress.utility,"show_mark":_vm.simInfo.show_page_mark},on:{"endPage":_vm.nextPage}}):_vm._e()],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }